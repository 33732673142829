import React from "react"

import Playlist from "../components/playlist"

import imgbanner from "../images/index/thumb/bloom.jpg"
import allcategories from "../content/categorytitles.json"
import videolist from "../content/playlistbloom.json"


const BloomPage = ({location}) => (
  <Playlist
    location={location}
    headingClassName={"color-text-secondary"}
    path={"bloom"}
    bannerimg={imgbanner}
    title={allcategories.bloom.title}
    writeup={allcategories.bloom.description} 
    videolist={videolist} />
)

export default BloomPage
